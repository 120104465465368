import React from "react"
import styled from "styled-components"
import OffCanvas, { IOffCanvasProps } from "lib/OffCanvas"

const StyledPopOver = styled(OffCanvas)<IPopOverProps>`
  z-index: ${(props) => props.zindex};
  overflow-x: hidden;
  max-height: 100%;
  box-shadow: -1px 0px 4px -1px rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity ${(props) => (props.show ? "0ms" : "0.7s")} steps(1);
`

interface IPopOverProps extends IOffCanvasProps {
  zindex?: number
}

const PopOver: React.FC<IPopOverProps> = ({
  selector = "#___offcanvas",
  children,
  zindex = 80,
  ...props
}) => {
  // useLockBodyScroll(true)
  return (
    <StyledPopOver selector={selector} zindex={zindex} {...props}>
      {children}
    </StyledPopOver>
  )
}

export default PopOver
