import React, { useContext } from "react"
import PropTypes from "prop-types"
import Tracker from "../Tracker"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { IDevice } from "app/Device/types"
import { useDevices } from "app/Device/hooks"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { AppContext } from "context"

interface ITrackerItemProps {
  groupID: number
  deviceID: number
  simple: boolean
  device: IDevice
}

const TrackerItem: React.FC<ITrackerItemProps> = (props) => {
  const { deviceID, groupID, children, simple, ...restProps } = props
  const { dispatch } = useContext(DeviceContext)
  const { dispatch: appDispatch } = useContext(AppContext)
  const { showDeviceHealth } = useDevices()

  const onClickHandler = (e) => {
    e.stopPropagation()
    // Toggle specific tracker
    if (simple) {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: false })
      )
      dispatch(
        DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
          groupID,
          deviceID,
          untoggleRest: true,
        })
      )
    } else {
      dispatch(
        DeviceActions(DeviceTypes.ToggleDeviceInGroup, {
          groupID,
          deviceID,
        })
      )
    }
  }

  const toggleDeviceHealth = showDeviceHealth(restProps.device)

  return (
    <Tracker
      onClick={onClickHandler}
      simple={simple}
      groupID={groupID}
      deviceID={deviceID}
      showDeviceHealth={toggleDeviceHealth}
      {...restProps}
    />
  )
}

export default TrackerItem

TrackerItem.defaultProps = {}
TrackerItem.propTypes = {
  children: PropTypes.node,
}
