import React, { useState, useEffect, useRef } from "react"
import tw from "twin.macro"
import I18n from "app/I18n"
import styled, { css } from "styled-components"
import { Group as ListGroup } from "lib/List/Group"
import { Button, ButtonGroup, Icon, Responsive } from "@clevertrack/shared"
import Panel from "app/Panel"
import ServiceItem from "../ServiceItem"
import CreateInterval from "../ServiceItem/CreateInterval"
import { IntervalModel } from "./intervalModel"
import { LogModel } from "./logModel"
import {
  getIntervalValue,
  serviceLogInjectOriginal,
  serviceLogIsDifferent,
} from "./helper"
// import Editable from "../Editable"
import AdminComponent from "app/AdminComponent"

import {
  createServiceEntry,
  updateServiceEntry,
  deleteServiceEntry,
} from "services/serviceLog"
import KpiGrid from "../Result/KpiGrid"
import EditTimeline from "./SubComponents/EditTimeline"
import cogoToast from "@clevertrackdk/cogo-toast"
import { ServiceInformation } from "./SubComponents/ServiceInformation"
import { useUser } from "app/User/hooks"
import {
  DeviceDocumentUpload,
  DeviceFilePathEnum,
} from "app/DocumentUpload/DeviceDocumentUpload"

const StyledEditService = styled(Panel)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  padding-top: 0;

  > section {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    > section:first-of-type {
      align-self: flex-start;
      position: sticky;
      top: 0;
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 1rem;
    > section {
      padding-bottom: 0;
    }

    > section:first-of-type {
      align-self: flex-start;
      position: relative;
      top: auto;
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    > section:first-of-type {
      align-self: flex-start;
      position: sticky;
      top: 0;
    }
  `}
`

const Title = styled.div`
  padding-bottom: 2rem;
  font-weight: bold;
  h3 {
    margin-bottom: 0;
  }
`

export const TrackerLabel = styled.small`
  color: ${(props) => props.theme.colors.gray};
  margin-top: 0;
`

const LogNoUI = styled.div`
  margin-top: 6rem;
  margin-left: 0.4rem;
  opacity: 0.3;
  font-size: 1.8rem;
  // width: 42%;
  text-align: left;

  span {
    margin-top: 6rem;
    display: block;
    font-size: 0.75em;
  }
`

const LogDetail = styled.div`
  color: ${(props) => props.theme.colors.blackOpaque};
  font-size: 80%;
  font-weight: bold;
`

const Calibration = styled(LogDetail)`
  ${(props) =>
    !!props.edit === true &&
    css`
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dotted;
      &:hover {
        color: ${(props) => props.theme.colors.primary};
      }
    `}
`

const LogButton = styled(Button)`
  ${tw`ml-0`}
`

const StyledKpiGrid = styled(KpiGrid)`
  padding: 0;
  margin-top: 0.3rem;

  div {
    padding: 0;
  }
`

const StyledListGroup = styled(ListGroup)`
  margin-top: 1.2rem;
`

const NoteFieldLineHeight = 1.6

function EditService({
  data,
  onCancel,
  showSetup,
  setSetupParams,
  setInterval,
  fetch,
  user,
  onUpdateInterval,
  ...props
}) {
  const [displayNoUI, setDisplayNoUI] = useState(true)
  const { canWrite } = useUser()

  // const [contentHTML, setContentHTML] = useState('')

  const [registerService, setRegisterService] = useState([false, null])
  const [currentInterval, setCurrentInterval] = useState(null)
  const [editMode, setEditMode] = useState([])

  const [serviceEntry, setServiceEntry] = useState(new LogModel())

  const [date, setDate] = useState(new Date())

  const onSetDateHandler = (d) => {
    setDate(d)
  }

  const onIntervalChange = (key, value) => {
    setCurrentInterval((prev) => ({ ...prev, [key]: value }))
  }

  const onEntryChange = (obj) => {
    setServiceEntry({
      ...serviceEntry,
      ...obj,
    })
  }

  useEffect(() => {
    onEntryChange({ realDate: date })
  }, [date])

  // useEffect(() => {
  //   // console.log("ENTRY:", serviceEntry)
  // }, [serviceEntry])

  useEffect(() => {
    if (!!data.log) {
      setEditMode(data.log.map(() => false))
    }
  }, [data])

  const editHandler = (e, i, id, isCancelEvent) => {
    e.preventDefault()
    const logItem = e.target.parentNode.parentNode.parentNode
    const noteField = logItem.querySelector("textarea")
    setTimeout(() => {
      if (noteField) noteField.focus()
    }, 0)

    setEditMode(editMode.map((x, index) => (index === i ? !x : false)))
    cancelRegisterService()

    const originalEntry = data.log.find((x) => x.id === id)
    if (!isCancelEvent) {
      if (originalEntry.interval.type === "Reparation") {
        setCurrentInterval(originalEntry.interval)
      } else {
        const entryInterval = data.intervals.find(
          (interval) => interval.type === originalEntry.interval.type
        )
        setCurrentInterval(entryInterval)
      }
    }
    setServiceEntry({
      ...originalEntry,
      realDate: new Date(originalEntry.realDate),
    })
  }

  const onRegisterService = (i, interval) => {
    if (currentInterval && interval.id === currentInterval.id) {
      cancelRegisterService()
      return
    }
    setCurrentInterval(interval)
    if (registerService[1] === i && registerService[0]) {
      setRegisterService([false, null])
    } else {
      setRegisterService([true, i])
    }
    const logModel = new LogModel()
    logModel.realDate = new Date()
    logModel.realHours = data.hours
    logModel.realMileage = data.mileage
    setServiceEntry(logModel)
    // setEditMode(data.log.map(() => false))
    setDisplayNoUI(false)
  }

  const onRegisterNewReparation = () => {
    setCurrentInterval({
      type: "Reparation",
      unit: "mdr",
    })
    const logModel = new LogModel()
    logModel.realDate = new Date()
    logModel.realHours = data.hours
    logModel.realMileage = data.mileage
    setServiceEntry(logModel)
    setRegisterService([true, 0])
    setEditMode(data.log.map(() => false))
    setDisplayNoUI(false)
  }

  const cancelRegisterService = () => {
    const logModel = new LogModel()
    logModel.realDate = new Date()
    setServiceEntry(logModel)
    setCurrentInterval(null)
    setRegisterService([false, null])
    setDisplayNoUI(true)
  }

  const onClickHandler = (interval) => {
    // force update on same interval hack
    // important for calculating interval value on SetupForm
    // pls don't delete:
    interval = { ...interval }

    // other non-hacky code:
    setInterval(interval)
    showSetup(true)
    setSetupParams("edit")
    cancelRegisterService()
  }

  const onNewIntervalHandler = () => {
    const newInterval = new IntervalModel()
    setInterval(newInterval)
    showSetup(true)
    setSetupParams("new")
    cancelRegisterService()
  }

  const onCancelHandler = () => {
    cancelRegisterService()
    onCancel()
  }

  const onSubmitHandler = async () => {
    const newEntry = serviceEntry
    newEntry.interval = currentInterval
    newEntry.intervalstamp =
      currentInterval.type !== "Reparation"
        ? getIntervalValue(currentInterval)
        : 0
    newEntry.author = { ...user }

    // hack, waiting for backend fix
    newEntry.date = new Date()

    if (newEntry.note === "") {
      cogoToast.error("Note-feltet må ikke være tomt")
      return
    }

    // console.log(newEntry)
    try {
      debugger
      await onUpdateInterval(currentInterval)
      const response = await createServiceEntry(data.id, newEntry)

      // console.log("CREATE:", response)
      cancelRegisterService()
      setDisplayNoUI(false)
      fetch()
    } catch (err) {
      console.log(err)
    }
  }

  const onUpdateHandler = async (id) => {
    const originalEntry = data.log.find((x) => x.id === id)
    const newEntry = serviceEntry
    const injectedEntry = serviceLogInjectOriginal(originalEntry, newEntry)
    injectedEntry.interval = currentInterval
    injectedEntry.edit = {
      user,
      date: new Date(),
    }

    if (injectedEntry.note === "") {
      cogoToast.error("Note-feltet må ikke være tomt")
      return
    }
    debugger
    if (serviceLogIsDifferent(originalEntry, injectedEntry)) {
      try {
        await onUpdateInterval(currentInterval)
        const response = await updateServiceEntry(id, injectedEntry)

        cancelRegisterService()
        fetch()
      } catch (err) {
        console.log(err)
      }
    } else {
      cancelRegisterService()
      fetch()
    }
  }

  const userFullname = (user) =>
    !!user ? `${user.firstName} ${user.lastName}` : false

  const formatLang = "da-DK"

  return (
    <StyledEditService id="editService" {...props}>
      <section>
        <Title>
          <h3>Serviceintervaller</h3>
          <TrackerLabel>{data.name}</TrackerLabel>
          <small tw="opacity-60 block">{data.note}</small>
        </Title>
        <StyledListGroup>
          {data.intervals &&
            data.intervals
              .sort((a, b) => (a.index > b.index ? -1 : 1))
              .map((interval, i) => {
                return (
                  <ServiceItem
                    key={`service_interval_${i}`}
                    data={interval}
                    onClick={() => onClickHandler(interval)}
                    onServiceEntry={() => onRegisterService(i, interval)}
                    css={[
                      currentInterval && interval.id !== currentInterval.id
                        ? tw`opacity-60 relative z-40`
                        : tw`relative z-50`,
                    ]}
                  />
                )
              })}
          {canWrite && <CreateInterval onClick={onNewIntervalHandler} />}
        </StyledListGroup>

        {canWrite && (
          <StyledListGroup tw="mb-2 mt-8">
            <Title tw="pb-0 mb-4">
              <h3>Vedligehold</h3>
            </Title>
            <CreateInterval
              tw="bg-white"
              type="reparation"
              onClick={() =>
                currentInterval && currentInterval.type === "Reparation"
                  ? cancelRegisterService()
                  : onRegisterNewReparation()
              }
              title="Reparation"
              css={[
                currentInterval &&
                  currentInterval.type !== "Reparation" &&
                  tw`opacity-60`,
              ]}
            />
          </StyledListGroup>
        )}

        <ServiceInformation deviceID={data.id} />
        <DeviceDocumentUpload
          deviceID={data.id}
          path={DeviceFilePathEnum.VehicleDocuments}
          toggleUploadActionText="Tilføj dokumenter"
          fileListHeadingText="Dokumenter"
          offCanvasRootSelector="#editService"
        />

        <Responsive
          tabletPortrait={
            <ButtonGroup style={{ marginTop: "2rem" }}>
              <Button size="sm" onClick={onCancelHandler}>
                Luk
              </Button>
            </ButtonGroup>
          }
          phone={<></>}
          desktop={<></>}
        />
      </section>
      <section tw="pb-24">
        <Title tw="mb-0">
          <h3>Service log</h3>
          <StyledKpiGrid
            data={data}
            tw="space-x-12"
            show
            displayHours={data.hours > 0}
            displayMilage={data.mileage > 0}
          />
        </Title>
        {displayNoUI && data.log && data.log.length < 1 && (
          <>
            <LogNoUI>
              Ingen logninger at vise
              {canWrite && (
                <span>Vælg et serviceinterval for at registrere service</span>
              )}
            </LogNoUI>
          </>
        )}
        <EditTimeline
          serviceEntry={serviceEntry}
          registerService={registerService}
          currentInterval={currentInterval}
          data={data}
          user={user}
          cancelRegisterService={cancelRegisterService}
          onSubmitHandler={onSubmitHandler}
          editMode={editMode}
          editHandler={editHandler}
          onUpdateHandler={onUpdateHandler}
          setDate={onSetDateHandler}
          onEntryChange={onEntryChange}
          onIntervalChange={onIntervalChange}
          formatLang={formatLang}
          userFullname={userFullname}
          setDisplayNoUI={setDisplayNoUI}
          fetch={fetch}
        />
      </section>
      <Responsive
        phone={
          <ButtonGroup
            position="center"
            tw="bg-brand-gray-lighter justify-center px-8 items-center"
            sticky
            style={{ bottom: "6.7rem" }}
          >
            <Button variant="default" onClick={onCancelHandler}>
              <Icon icon="chevron-left" tw="w-4 h-4 mr-4" />
              <span>Tilbage</span>
            </Button>
          </ButtonGroup>
        }
        tabletPortrait={<></>}
      />
    </StyledEditService>
  )
}

export default EditService

export function EditableButtonGroup({
  onCancel,
  onSubmit,
  user,
  deleteId,
  fetch,
  setDisplayNoUI,
  setAllowStatedHeight,
  ...props
}) {
  return (
    <>
      <ButtonGroup position="right" tw="px-0">
        <LogButton size="sm" variant="default" onClick={onCancel} {...props}>
          Annuller
        </LogButton>
        <LogButton size="sm" variant="primary" onClick={onSubmit} {...props}>
          Gem
        </LogButton>
      </ButtonGroup>
    </>
  )
}

export function DeleteButtonGroup({
  user,
  deleteId,
  fetch,
  setDisplayNoUI,
  onCancel,
  ...props
}) {
  const [confirmDelete, setConfirmDelete] = useState(false)

  const onDeleteServiceEntry = async (id) => {
    try {
      const response = await deleteServiceEntry(id)
      setConfirmDelete(false)
      !!setDisplayNoUI && setDisplayNoUI(true)
      onCancel()
      fetch()
    } catch (err) {
      console.log(err)
    }
  }

  function onDeleteHandler() {
    setConfirmDelete(!confirmDelete)
  }

  return (
    <>
      {!!user && (
        <AdminComponent user={user}>
          {confirmDelete ? (
            <ButtonGroup position="right" tw="px-0 py-0 my-0">
              <LogButton
                variant="danger"
                size="sm"
                type="button"
                onClick={() => onDeleteServiceEntry(deleteId)}
              >
                <I18n strKey="confirm" />
              </LogButton>
              <LogButton
                variant="default"
                size="sm"
                type="button"
                transparent
                onClick={() => onDeleteHandler()}
              >
                <I18n strKey="cancel" />
              </LogButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup position="right" tw="px-0 py-0 my-0">
              <LogButton
                variant="danger"
                size="sm"
                type="button"
                onClick={() => onDeleteHandler()}
              >
                <I18n strKey="delete" />
              </LogButton>
            </ButtonGroup>
          )}
        </AdminComponent>
      )}
    </>
  )
}

export function EditableCalibration({
  unit,
  children,
  onChange,
  onBlur,
  initialValue,
  type,
  ...props
}) {
  const [editing, setEditing] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef?.current && editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEditing = (val) => {
    if (props.edit) {
      setEditing(val)
    }
  }

  let bool = true

  const onChangeHandler = (e) => {
    if (bool) {
      bool = false
    }
    onChange(e)
  }

  const onBlurHandler = (e) => {
    toggleEditing(false)
    if (onBlur) onBlur(e)
  }

  return (
    <Calibration
      onClick={() => toggleEditing(true)}
      /* onFocus={() => toggleEditing(true)}
      onBlur={() => toggleEditing(false)} */
      {...props}
    >
      {editing ? (
        <>
          <InlineInput
            ref={inputRef}
            type="number"
            defaultValue={initialValue}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
          />{" "}
          {unit}
        </>
      ) : (
        <>{children}</>
      )}
    </Calibration>
  )
}

const InlineInput = styled.input`
  padding: 0;
  border: none;
  font-weight: inherit;
  color: inherit;
  outline: none !important;
  background: transparent;
`

EditService.defaultProps = {}
EditService.propTypes = {}
